import React from 'react';
import { bool, node } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import classNames from 'classnames';

import { FormattedMessage, injectIntl, intlShape } from '../../../util/reactIntl';
import * as validators from '../../../util/validators';
import { Form, PrimaryButton, FieldTextInput, CustomExtendedDataField, FieldCheckbox, FieldSelect } from '../../../components';

import css from './SignupForm.module.css';
import { getBirthDates, getBirthMonths, getBirthYears, getPropsForCustomUserFieldInputs } from '../../../util/userHelpers';

const SignupFormComponent = props => (
  <FinalForm
    {...props}
    mutators={{ ...arrayMutators }}
    render={fieldRenderProps => {
      const {
        rootClassName,
        className,
        formId,
        handleSubmit,
        inProgress,
        invalid,
        intl,
        termsAndConditions,
        userFields,
        values,
      } = fieldRenderProps;

      // email
      const emailRequired = validators.required(
        intl.formatMessage({
          id: 'SignupForm.emailRequired',
        })
      );
      const emailValid = validators.emailFormatValid(
        intl.formatMessage({
          id: 'SignupForm.emailInvalid',
        })
      );

      // password
      const passwordRequiredMessage = intl.formatMessage({
        id: 'SignupForm.passwordRequired',
      });
      const passwordMinLengthMessage = intl.formatMessage(
        {
          id: 'SignupForm.passwordTooShort',
        },
        {
          minLength: validators.PASSWORD_MIN_LENGTH,
        }
      );
      const passwordMaxLengthMessage = intl.formatMessage(
        {
          id: 'SignupForm.passwordTooLong',
        },
        {
          maxLength: validators.PASSWORD_MAX_LENGTH,
        }
      );
      const passwordMinLength = validators.minLength(
        passwordMinLengthMessage,
        validators.PASSWORD_MIN_LENGTH
      );
      const passwordMaxLength = validators.maxLength(
        passwordMaxLengthMessage,
        validators.PASSWORD_MAX_LENGTH
      );
      const passwordRequired = validators.requiredStringNoTrim(passwordRequiredMessage);
      const passwordValidators = validators.composeValidators(
        passwordRequired,
        passwordMinLength,
        passwordMaxLength
      );


      const telephone_contactLabel = intl.formatMessage({
        id: 'ProfileSettingsForm.telephone_contactLabel',
      });
      const telephone_contactPlaceholder = intl.formatMessage({
        id: 'ProfileSettingsForm.telephone_contactPlaceholder',
      });
      const telephone_contactRequiredMessage = intl.formatMessage({
        id: 'ProfileSettingsForm.telephone_contactRequired',
      });
      const telephone_contactRequired = validators.required(telephone_contactRequiredMessage);

      const country_of_businessLabel = intl.formatMessage({
        id: 'ProfileSettingsForm.country_of_businessLabel',
      });
      const country_of_businessPlaceholder = intl.formatMessage({
        id: 'ProfileSettingsForm.country_of_businessPlaceholder',
      });
      const country_of_businessRequiredMessage = intl.formatMessage({
        id: 'ProfileSettingsForm.country_of_businessRequired',
      });
      const country_of_businessRequired = validators.required(country_of_businessRequiredMessage);

      const notifyOnSearchesLabel = intl.formatMessage({
        id: 'ProfileSettingsForm.notifyOnSearchesLabel',
      });

      const notifyOnSearchesRequiredMessage = intl.formatMessage({
        id: 'ProfileSettingsForm.notifyOnSearchesRequired',

      })

      const notifyOnSearchesRequired = validators.required(notifyOnSearchesRequiredMessage);

      // Custom user fields. Since user types are not supported here,
      // only fields with no user type id limitation are selected.
      const userFieldProps = getPropsForCustomUserFieldInputs(userFields, intl);

      const classes = classNames(rootClassName || css.root, className);
      const submitInProgress = inProgress;
      const submitDisabled = invalid || submitInProgress;

      return (
        <Form className={classes} onSubmit={handleSubmit}>
          <div>
            <FieldTextInput
              type="email"
              id={formId ? `${formId}.email` : 'email'}
              name="email"
              autoComplete="email"
              label={intl.formatMessage({
                id: 'SignupForm.emailLabel',
              })}
              placeholder={intl.formatMessage({
                id: 'SignupForm.emailPlaceholder',
              })}
              validate={validators.composeValidators(emailRequired, emailValid)}
            />
            <div className={css.name}>
              <FieldTextInput
                className={css.firstNameRoot}
                type="text"
                id={formId ? `${formId}.fname` : 'fname'}
                name="fname"
                autoComplete="given-name"
                label={intl.formatMessage({
                  id: 'SignupForm.firstNameLabel',
                })}
                placeholder={intl.formatMessage({
                  id: 'SignupForm.firstNamePlaceholder',
                })}
                validate={validators.required(
                  intl.formatMessage({
                    id: 'SignupForm.firstNameRequired',
                  })
                )}
              />
              <FieldTextInput
                className={css.lastNameRoot}
                type="text"
                id={formId ? `${formId}.lname` : 'lname'}
                name="lname"
                autoComplete="family-name"
                label={intl.formatMessage({
                  id: 'SignupForm.lastNameLabel',
                })}
                placeholder={intl.formatMessage({
                  id: 'SignupForm.lastNamePlaceholder',
                })}
                validate={validators.required(
                  intl.formatMessage({
                    id: 'SignupForm.lastNameRequired',
                  })
                )}
              />
            </div>
            <p style={{ fontSize: '12px' }}>Ensure Date-of-Birth is correct for KYC and account validation purposes. We cannot validate your account unless you have passed the KYC check (London Stock Exchange Group Risk Intelligence 'World Check' system)</p>
            <FieldSelect
              className={css.additionalInputs}
              type="number"
              id="birthYear"
              name="birthYear"
              label={intl.formatMessage({
                id: 'ProfileSettingsForm.birthYearLabel',
              })}
              validate={validators.required(intl.formatMessage({
                id: 'ProfileSettingsForm.birthYearRequired',
              }))}
            >
              <option value="">{intl.formatMessage({
                id: 'ProfileSettingsForm.birthYearPlaceholder',
              })}</option>
              {getBirthYears().map(year => <option>{year}</option>)}
            </FieldSelect>

            {values.birthYear && <FieldSelect
              className={css.additionalInputs}
              type="number"
              id="birthMonth"
              name="birthMonth"
              label={intl.formatMessage({
                id: 'ProfileSettingsForm.birthMonthLabel',
              })}
              validate={validators.required(intl.formatMessage({
                id: 'ProfileSettingsForm.birthMonthRequired',
              }))}
            >
              <option value="">{intl.formatMessage({
                id: 'ProfileSettingsForm.birthMonthPlaceholder',
              })}</option>
              {getBirthMonths().map(({ value, display }) => <option value={value + 1}>{display}</option>)}
            </FieldSelect>}

            {values.birthMonth && <FieldSelect
              className={css.additionalInputs}
              type="number"
              id="birthDate"
              name="birthDate"
              label={intl.formatMessage({
                id: 'ProfileSettingsForm.birthDateLabel',
              })}
              validate={validators.required(intl.formatMessage({
                id: 'ProfileSettingsForm.birthDateRequired',
              }))}
            >
              <option value="">{intl.formatMessage({
                id: 'ProfileSettingsForm.birthDatePlaceholder',
              })}</option>
              {getBirthDates(values.birthMonth, values.birthYear).map(date => <option>{date}</option>)}
            </FieldSelect>}

            <FieldTextInput
              className={css.password}
              type="password"
              id={formId ? `${formId}.password` : 'password'}
              name="password"
              autoComplete="new-password"
              label={intl.formatMessage({
                id: 'SignupForm.passwordLabel',
              })}
              placeholder={intl.formatMessage({
                id: 'SignupForm.passwordPlaceholder',
              })}
              validate={passwordValidators}
            />
            <div className={css.customFields}>
              {userFieldProps.map(fieldProps => (
                <CustomExtendedDataField {...fieldProps} formId={formId} />
              ))}
              <FieldSelect
                className={css.additionalInputs}
                id={formId ? `${formId}.notifyOnSearches` : 'notifyOnSearches'}
                name="notifyOnSearches"
                label={notifyOnSearchesLabel}
                value="notifyOnSearches"
                validate={notifyOnSearchesRequired}
              >
                <option></option>
                <option>Yes</option>
                <option>No</option>
              </FieldSelect>
              <FieldTextInput
                className={css.additionalInputs}
                type="text"
                id="telephone_contact"
                name="telephone_contact"
                label={telephone_contactLabel}
                placeholder={telephone_contactPlaceholder}
                validate={telephone_contactRequired}
              />
              <FieldTextInput
                className={css.additionalInputs}
                type="text"
                id="country_of_business"
                name="country_of_business"
                label={country_of_businessLabel}
                placeholder={country_of_businessPlaceholder}
                validate={country_of_businessRequired}
              />

            </div>
          </div>

          <div className={css.bottomWrapper}>

            {termsAndConditions}
            <PrimaryButton type="submit" inProgress={submitInProgress} disabled={submitDisabled}>
              <FormattedMessage id="SignupForm.signUp" />
            </PrimaryButton>
          </div>
        </Form>
      );
    }}
  />
);

SignupFormComponent.defaultProps = { inProgress: false };

SignupFormComponent.propTypes = {
  inProgress: bool,
  termsAndConditions: node.isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const SignupForm = compose(injectIntl)(SignupFormComponent);
SignupForm.displayName = 'SignupForm';

export default SignupForm;
